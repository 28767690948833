import axios from '../utils/axios'

interface Response {
    status: number,
    data: any,
    msg: string
}
export const login = async (reqData,successcb, errcb) => {
    try {
      const { data } = await axios.post('/api/user/login',reqData)
      const { status,msg } = data
      if (status === 200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    } catch (error) {
      errcb("网络错误，login请重试")
    }
  }

export const getUserInfo = async (cb:Function) => {
  const {data} = await axios.post('/api/user/getUserInfo')
  cb(data)
}

export const saveRegist = async (reqData,successcb, errcb) => {
  try {
    const { data } = await axios.post('/api/user/regist',reqData)
    const { status,msg } = data
    if (status === 200) {
      successcb(data)
    } else {
      errcb(msg)
    }
  } catch (error) {
    errcb("网络错误，saveRegist请重试")
  }
}

export const getCategory = async (successcb, errcb)=>{
    try{
      const {data} = await axios.get('/api/category')
      const {status,msg} = data
      if(status === 200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){
      errcb("getcategory错误，请重试")
    }
}

export const delCategory = async (reqData, successcb, errcb) => {
  try{    
    const {data} = await axios.post('/api/category/delcategory',reqData)
    const {status, msg} =data
    if (status ===200) {
      successcb(data)
    } else {
      errcb(msg)
    }
  }catch(err){
    errcb("delCategory错误，请重试")
  }
}

  export const addCategory = async (reqData, successcb, errcb) => {
    try{
      const {data} = await axios.post('/api/category/addcategory', reqData)
      const {status, msg} =data
      if (status === 200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){
      errcb("addCategory错误，请重试")
    }
  }

  export const updateCategory = async (reqData, successcb, errcb) => {
    try{
      const {data} = await axios.post('/api/category/updatecategory', reqData)
      const {status, msg} =data
      if (status === 200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){
      errcb("updateCategory错误，请重试")
    }
  }

  export const getproduct = async (successcb, errcb) =>{
    try{
      const {data} = await axios.get('/api/product')
      const {status,msg} = data
      if(status === 200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){
      errcb("getproduct错误，请重试")
    }
  }
  
  export const getProductById = async (reqData, successcb, errcb) =>{
    try{
      const {data} = await axios.post('/api/product/getProductById', reqData)
      const {status, msg} =data
      if (status === 200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){
      errcb("")
    }
  }

  export const updateproduct = async (reqData, successcb, errcb) =>{
    try{
      const {data} = await axios.post('/api/product/updateproduct', reqData)
      const {status, msg} =data
      if (status === 200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){
      errcb("updateproduct错误，请重试")
    }
  }
  export const delproduct = async (reqData, successcb, errcb) =>{
    try{    
      const {data} = await axios.post('/api/product/delproduct',reqData)
      const {status, msg} =data
      if (status ===200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){
      errcb("delproduct错误，请重试")
    }
  }
  export const addproduct = async (reqData, successcb, errcb) =>{
    try{
      const {data} = await axios.post('/api/product/addproduct', reqData)
      const {status, msg} =data
      if (status === 200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){
      errcb("addproduct错误，请重试")
    }
  }
  export const getProductPicById = async(reqData, successcb, errcb) =>{
    try{
      const{data} = await axios.post('/api/product/getProductPicById', reqData)
      const {status, msg} = data
      if (status === 200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){
     errcb("getProductPicById错误")
    }
  }

  export const Upload = async(reqData, successcb, errcb) => {
    try{
      const {data} = await axios.post('/api/upload',reqData,{headers: {
        'Content-Type': 'multipart/form-data',
      },})
      const {status, msg} = data
    }catch(err){
      errcb("get upload 错误")
    }
  }

  export const getPicture = async(successcb, errcb) => { 
      try{
        const {data} = await axios.get('/api/picture/')
        const {status, msg} = data
        if(status === 200) {
          successcb(data)
        } else {
          errcb(msg)
        }
      }catch(err){
        errcb(err)
      }
  }

  export const getPictureByProductid = async(reqData, successcb, errcb) => {
    try{
      const {data} = await axios.post('/api/picture/getPictureByProductid', reqData)
      const {status, msg} =data
      if (status === 200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){
      errcb("")
    }
  }

  export const addproductPiture =async (reqData, successcb, errcb) => {
    try{
      const {data} = await axios.post('/api/picture/addpicture', reqData)
      const {status, msg} =data
      if (status === 200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){      
      errcb("addproductPiture错误，请重试")
    }
  }

  export const delProductPicture = async(reqData, successcb, errcb) => {
    try{    
      const {data} = await axios.post('/api/picture/delpicture',reqData)
      const {status, msg} =data
      if (status ===200) {
        successcb(data)
      } else {
        errcb(msg)
      }
    }catch(err){
      errcb("delproductpicture错误，请重试")
    }   
  }

export default axios